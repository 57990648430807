
*{
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
      /* background-image: url(./assets/img/dibujo-de-motocicleta-en-blanco-y-negro-2gpm7xk\ \(2\).jpg);
      background-size: 18%;
      background-repeat: no-repeat;
      position: relative; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.registros{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 8px;
  padding: 3rem;
  margin: 4rem 3rem;
  position: relative;
  z-index: 1500;
}


.fileInput{
  width: 100%;
  
}