.fondo{
  background-image: url(../assets/img/fondo.svg);
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
a{
  color: white;
  text-decoration:none
}
.title-credencial{
  margin-top:17px;
  color:white;
  text-align: center;
  margin-bottom:-15px;
}

section{
  display: flex;
  flex-direction: column;
}
.datos-generales{
  display: flex;
  flex-direction: row;
}
.datos-generales .img {
  border-radius: 8px;
  width: 185px;
  height: 274px;
  margin: 38px 31px 27px 15px;
}
.datos-generales .general{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.general .contactos{
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 92%;
}
.credencial img{
  width:93px;
  height:93px;
  margin-top:0px;
}
.credencial{
  width:93px;
  height:93px;
}
.redes{
  text-align: left;
  width: 100%;
  flex-basis: 100%;
  align-items:center;
}
.redes p{
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-size:14px;
  color: white;
}
.redes a{
  margin-top: 10px;
  line-height:50px;
}
.redes .whats{
  padding-top: -20px;
  color: rgb(35, 192, 14);
}
.redes span{
  margin-right:10px;
}
.redes span svg {
  padding-top:10px;
}
.datos{
  text-align:left;
  width:95%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.datos h3{
  font-size: 13px;
  color: white;
  font-weight: bolder;
}
.datos p{
  font-size:14px;
  color: white;
  margin-bottom:16px;
}
.datos2{
 width:170px;
}

.historial h2{
  margin-top: 20px;
  border: 2px solid white;
  height:48px;
  line-height:48px;
  text-align: center;
  font-size:22px;
  color: white;
  border-radius: 8px 8px 0 0;
  width:100%;
  margin-left: -2px;
}

.item-historial{
  margin:0 50px;
}
.item-historial h3{
  color: white;
  text-align: center;
  margin:10px 0 25px 0;
}
.item-historial article{
  display: flex;
  justify-content:space-between;
}
.item-informacion h4 {
  font-size:12px;
  margin-bottom: 20px;
  color: white;
}
.item-registro{
  width:270px;
}
.item-registro p {
  text-align: left;
  font-size:14px;
  color: white;
  margin-bottom: 16px;
}
